var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cookies-overview"
  }, [_c('SldDetails', {
    attrs: {
      "sld-data": _vm.sldData,
      "sld-open": _vm.sldOpen
    },
    on: {
      "close": _vm.closeDrawer
    }
  }), _c('div', [_vm.scopeQs ? _c('div', [!_vm.userHasRole('publisher-fp') ? _c('Row', {
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      "type": "flex",
      "gutter": 24
    }
  }, [_c('Col', {
    attrs: {
      "lg": 8,
      "md": 8,
      "xs": 24,
      "sm": 24
    }
  }, [!_vm.userHasRole('publisher-fp') ? _c('WidgetTile', {
    attrs: {
      "is-actionable": true,
      "api": _vm.tabs.beforeConsent.widget
    }
  }) : _vm._e()], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 8
    }
  }, [!_vm.userHasRole('publisher-fp') ? _c('WidgetTile', {
    attrs: {
      "is-actionable": true,
      "api": _vm.tabs.afterConsent.widget
    }
  }) : _vm._e()], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 8
    }
  }, [!_vm.userHasRole('publisher-fp') ? _c('WidgetTile', {
    attrs: {
      "is-actionable": true,
      "api": _vm.tabs.noConsent.widget
    }
  }) : _vm._e()], 1), _vm._v("   ")], 1) : _vm._e(), _c('Row', {
    staticClass: "table-container"
  }, [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('Tabs', {
    attrs: {
      "type": "card",
      "animated": false,
      "data-tests": "trackers-table-tabs"
    },
    model: {
      value: _vm.currentTab,
      callback: function callback($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, _vm._l(Object.values(_vm.tabs), function (tab) {
    return _c('TabPane', {
      key: tab.name,
      staticClass: "tab-panel",
      attrs: {
        "label": _vm.counts[tab.name] + tab.label,
        "name": tab.name
      }
    }, [_c('br'), _c('p', [_c('strong', [_vm._v(_vm._s(tab.desc) + " ")])]), _c('FilterTable', {
      attrs: {
        "fetch-items": _vm.cookieGetCookies,
        "namespace": 'cookie',
        "export-csv": _vm.exportCsv,
        "fetch-items-params": _vm.params(tab.name),
        "columns": _vm.generateCols,
        "limit": 10,
        "search-filters": ['search', 'show-category-exemption', 'isExempted'],
        "data-tests": "trackers-table"
      },
      on: {
        "on-items-fetched": function onItemsFetched(res) {
          return _vm.onCookiesFetched(res, tab.name);
        }
      }
    })], 1);
  }), 1)], 1)], 1), _c('br'), _c('WidgetFocus')], 1) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }