<template>
  <div class="cookies-overview">
    <SldDetails :sld-data="sldData"
                :sld-open="sldOpen"
                @close="closeDrawer"
    />
    <div>
      <div v-if="scopeQs">
        <Row
          v-if="!userHasRole('publisher-fp')"
          style="margin-bottom:24px"
          type="flex"
          :gutter="24"
        >
          <Col
            :lg="8"
            :md="8"
            :xs="24"
            :sm="24"
          >
            <WidgetTile
              v-if="!userHasRole('publisher-fp')"
              :is-actionable="true"
              :api="tabs.beforeConsent.widget"
            />
          </Col>
          <Col
            :xs="24"
            :sm="24"
            :md="8"
          >
            <WidgetTile
              v-if="!userHasRole('publisher-fp')"
              :is-actionable="true"
              :api="tabs.afterConsent.widget"
            />
          </Col>
          <Col
            :xs="24"
            :sm="24"
            :md="8"
          >
            <WidgetTile
              v-if="!userHasRole('publisher-fp')"
              :is-actionable="true"
              :api="tabs.noConsent.widget"
            />
          </Col>
        &nbsp;
        </Row>
        <Row class="table-container">
          <Col span="24">
            <Tabs
              type="card"
              :animated="false"
              v-model="currentTab"
              data-tests="trackers-table-tabs"
            >
              <TabPane
                v-for="(tab) in Object.values(tabs)"
                :key="tab.name"
                class="tab-panel"
                :label="counts[tab.name] + tab.label"
                :name="tab.name"
              >
                <br>
                <p> <strong>{{ tab.desc }} </strong></p>
                <FilterTable
                  :fetch-items="cookieGetCookies"
                  :namespace="'cookie'"
                  :export-csv="exportCsv"
                  :fetch-items-params="params(tab.name)"
                  :columns="generateCols"
                  :limit="10"
                  :search-filters="['search','show-category-exemption', 'isExempted']"
                  @on-items-fetched="res => onCookiesFetched(res, tab.name)"
                  data-tests="trackers-table"
                />
                <!-- <WidgetTile v-if="!userHasRole('publisher-fp')" :isActionable="true" :api="tab.widget"></WidgetTile> -->
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        <br>
        <WidgetFocus />
      </div>
    </div>
  </div>
</template>

<script>
import pageConfig from '@/mixins/pageConfig'
import tableColumn from '@/mixins/tableColumn'
import FilterTable from '@/components/ui/FilterTable'
import WidgetTile from '@/components/ui/WidgetTile'
import SldDetails from '@/views/Cookies/SldDetails'

import WidgetFocus from '@/components/ui/WidgetFocus'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Index',
  mixins: [pageConfig, tableColumn],
  components: {
    FilterTable,
    SldDetails,
    WidgetTile,
    WidgetFocus
  },
  watch: {
    '$route': {
      handler: function(search) {
        const hash = this.$route.hash
        if (hash === '#despite') this.currentTab = 'noConsent'
        if (hash === '#nochoice') this.currentTab = 'beforeConsent'
        if (hash === '#accept') this.currentTab = 'afterConsent'
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      currentTab: 'activeTrackers',
      loadingTable: true,
      sldOpen: false,
      sldData: {},
      counts: {
        activeTrackers: '...',
        beforeConsent: '...',
        afterConsent: '...',
        noConsent: '...'
      },
      tabs: {
        activeTrackers: {
          name: 'activeTrackers',
          desc: `${this.$t('global.scenarios.allCookies')}`,
          params: {},
          label: ` ${this.$t('global.scenarios.allCookies')}`
        },
        beforeConsent: {
          name: 'beforeConsent',
          desc: `${this.$t('global.scenarios.cookies_desc.without_choice')}`,
          widget: 'wcookies/dailyCountBeforeConsentCookiesByParty',
          params: {cmpScenarioOutcomes: 'NO_ACTION'},
          label: ` ${this.$t('global.scenarios.without_choice')}`
        },
        afterConsent: {
          name: 'afterConsent',
          desc: `${this.$t('global.scenarios.cookies_desc.with_choice')}`,
          widget: 'wcookies/dailyCountAfterConsentCookiesByParty',
          params: {cmpScenarioOutcomes: 'ACCEPT'},
          label: ` ${this.$t('global.scenarios.with_choice')}`
        },
        noConsent: {
          name: 'noConsent',
          desc: `${this.$t('global.scenarios.cookies_desc.refusal_choice')}`,
          widget: 'wcookies/dailyCountDespiteRefusalCookiesByParty',
          params: {cmpScenarioOutcomes: 'REJECT'},
          label: ` ${this.$t('global.scenarios.refusal_choice')}`
        }
      }
      // columns: [

      // ]
    }
  },
  methods: {
    ...mapActions({
      cookieGetCookies: 'cookie/getCookies',
      exportCsv: 'cookie/exportCsv'
    }),
    clickTracker (data, context) {
      data.context = context
      this.sldData = data
      this.sldOpen = true
    },
    closeDrawer () {
      this.sldOpen = false
      this.sldData = {}
    },
    doesHelperColumn () {
      const { showCategoryHelper } = this
      return showCategoryHelper
        ? {
          key: 'Category.name',
          title: this.$t('global.table.category'),
          sortable: 'custom',
          sortKey: 'Categories.name',
          render: (h, params) => {
            if (!(params.row.vendor && params.row.vendor.category && params.row.vendor.category.description)) return h('span', {}, 'Under qualification')
            return h('Poptip', {
              props: {
                content: (params.row.vendor && params.row.vendor.category && params.row.vendor.category.description) ? params.row.vendor.category.description : 'Under qualification',
                placement:"right",
                'word-wrap': true,
                style:{wordWrap: 'break-word'},
                width: 300,
                trigger:'hover'
              }
            }, [
              h('Badge', {
                slot:"title",
                props: {
                  color: this.complianceColor[this.vendorVigilanceToLetter(params.row.vendor.category.vigilance_id)],
                  text: this.$t('global.vendor.category_vigilance') + ' ' + this.vendorVigilanceToLetter(params.row.vendor.category.vigilance_id)
                }
              }),
              h('span', {}, [
                params.row.vendor.category.is_exempted ? h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}) : '',
                params.row.vendor.category.name
              ])
            ])
          }}
        : {
          title: this.$t('global.table.category'),
          sortKey: 'Categories.name',
          sortable: 'custom',
          render: (h, params) => h('span', {}, [
            params.row.vendor.category.is_exempted ? h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}) : '',
            params.row.vendor.category.name
          ])
        }


    },
    getCookieAgeInMonthOrDays (days) {
      days = parseInt(days)
      if (typeof days !== 'number') return 'N/A'
      if (days > -31 && days < 31) return days + ' ' + this.$t('global.time.days')
      const month = Math.round(days / 30.5)
      return month + ' ' + this.$t('global.time.months')
    },
    onCookiesFetched (res, tabName) {
      this.counts[tabName] = res.pagination.count
    },
    params (tabName) {
      return {
        sort: 'Cookies.name',
        properties: [this.scopeSelectedProperty.id],
        period: this.scopeSelectedDaterange,
        isFirstParty: this.userHasRole('publisher-fp') ? 1 : null,
        ...this.tabs[tabName].params
      }
    }
  },
  computed: {
    ...mapGetters({
      scopeQs: 'scope/qs',
      scopeSelectedProperty: 'scope/selectedProperty',
      scopeSelectedDaterange: 'scope/selectedDaterange',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      userHasRole: 'user/hasRole',
      showCategoryHelper: 'vendor/showCategoryHelper',
      complianceColor: 'vendor/riskColor'
    }),
    generateCols () {
      return [
        {
          title: 'Name',
          key: 'Cookies.name',
          sortable: 'custom',
          sortKey: 'Cookies.name',
          render: (h, params) => {
            const t = this
            if (this.userHasRole('evidence-viewer') || this.userHasRole('super-admin')) {
              return h('a', {
                on: {
                  click: () => {t.clickTracker(params.row, 'tracker')}
                }
              }, params.row.name)
            }
            return h('span', {}, params.row.name)
            // return h('a', {
            //   on: {
            //     click: () => {t.clickTracker(params.row, 'tracker')}
            //   }
            // }, params.row.name)
          }
        },
        // this.createColumn(this.$t('global.table.name'), 'name', 'Cookies.name'),
        {
          title: this.$t('global.filters.is_exempted'),
          sortable: 'custom',
          sortKey: 'Cookies.is_exempted',
          render: (h, params) => {
            const render = h('Tag', {
              props: {
                color: 'blue'
              }
            }, 'Exempted')
            return params.row.is_exempted === 1 ? render : ''
          }
        },
        this.createColumn(this.$t('global.table.domain'), 'host', 'Cookies.host'),
        this.createColumn(this.$t('global.table.party'), 'key', 'Cookies.is_first_party', row => ({
          content: row.isFirstParty ? '1st party' : '3rd party'
        })),
        {
          title: 'Initiator',
          sortKey: 'Cookies.initiator_vendor_name_tmp',
          key: 'initiator_vendor_name_tmp',
          sortable: 'custom',
          render: (h, params) => {

            return params?.row?.initiator_vendor?.uuid
              ? h('router-link', {
                props: {
                  to: `/vendors/${params.row.initiator_vendor.uuid}`
                },
                style: {
                  fontWeight: 'bold'
                },
                attrs: {
                  title: params?.row?.initiator_host
                }
              }, params.row.initiator_vendor.name || 'N/A')
              : h('span', {
                class:"toto",
                attrs: {
                  title: params?.row?.initiator_host
                }
              }, 'N/A')
          }
        },
        {
          title: 'Initiator category',
          sortKey: 'Cookies.initiator_vendor_category_tmp',
          key: 'initiator_vendor_category',
          sortable: 'custom'
        },
        this.createColumn(this.$t('global.table.vendor'), 'vendor', 'Vendors.name', row => {
          if (row.vendor && row.vendor.name) {
            return {
              tag: 'router-link',
              props: {to: `/vendors/${row.vendor.uuid}`},
              content: row => ({tag: 'strong', content: row.vendor.name})
            }
          }
          return {tag: 'span', content: (row.vendor && row.vendor.name) ? row.vendor.name : 'Under qualification'}
        }),
        this.doesHelperColumn(),
        // this.createColumn(this.$t('global.table.category'), 'categories', 'Categories.name', row => ({
        //   content: (row.vendor && row.vendor.category && row.vendor.category.name) ? row.vendor.category.name : 'Under qualification'
        // })),
        this.createColumn(this.$t('global.table.type'), 'type', 'Cookies.type', null, 90),
        this.createColumn(this.$t('global.table.lifetime'), 'lifetime', 'Cookies.lifetimeDays', row => ({
          content: this.getCookieAgeInMonthOrDays(row.lifetimeDays)
        })),
        {
          key: 'cmp_scenario_outcome',
          sortable: 'custom',
          sortKey: 'Cookies.cmp_scenario_outcome',
          title: 'Scenario',
          render: (h, params) => h('span', {}, params.row.cmp_scenario_outcome === 'ACCEPT' ? 'With explicit choice' : params.row.cmp_scenario_outcome === 'REJECT' ? 'Despite refusal' : 'Without explicit choice' )

        }]
    }
  }
}
</script>
